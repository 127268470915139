import { styled } from '@mui/system'
import { Typography, Button, IconButton } from '@mui/material'

import { ReactComponent as PlusIcon } from '../../../icons/plusIcon_16x16.svg'
import { ReactComponent as EyeIcon } from '../../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../../icons/eyeOffIcon_16x16.svg'

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(0.75),
  minHeight: theme.spacing(5.5),
  background: theme.palette.surface.subtle,
  borderTopLeftRadius: theme.shape.borderRadius.xxs,
  borderTopRightRadius: theme.shape.borderRadius.xxs,
}))

const QuestionType = styled(Typography)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.hint,
  fontWeight: theme.fontWeights.semiBold,
  span: {
    color: theme.palette.text.link,
  },
}))

const AddQuestionButton = styled(Button)(({ theme }) => ({
  height: '32px',
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xs,
  color: theme.palette.neutral.darkest,
  marginRight: theme.spacing(3.25),
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
}))

const CodeBuilderQuestionHeader = ({
  question,
  canAddQuestions = false,
  updateQuestion,
  handleAddQuestion,
  questionLoading,
}) => {
  const updateQuestionEnabled = (questionId) => {
    updateQuestion(questionId, { enabled: !question.enabled })
  }

  const renderHeaderContent = () => {
    if (question.questionType !== 'code') {
      return (
        <>
          <QuestionType variant="body1">
            Question Type:{' '}
            <span>{question.config?.questionTypeDescription}</span>
          </QuestionType>
          {canAddQuestions && (
            <AddQuestionButton
              variant="outlined"
              startIcon={<PlusIcon />}
              color="tertiary"
              disabled={questionLoading}
              onClick={() => handleAddQuestion(question.id)}>
              Add a question
            </AddQuestionButton>
          )}
          {question.config?.canToggleEnable && (
            <IconButton onClick={() => updateQuestionEnabled(question.id)}>
              {question.enabled ? <EyeIcon /> : <EyeOffIcon />}
            </IconButton>
          )}
        </>
      )
    }
  }

  return <HeaderContainer>{renderHeaderContent()}</HeaderContainer>
}

export default CodeBuilderQuestionHeader
