import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import FormFieldError from '../../common/FormFieldError'

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'marginTop',
})(({ marginTop }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: marginTop || 0,
}))

const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const InputDescription = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visibility',
})(({ theme, visibility }) => ({
  display: 'flex',
  visibility: visibility || 'visible',
  justifyContent: 'center',
  alignItems: 'center',
  padding: visibility === 'hidden' ? '0 0.875rem' : '0.875rem',
  backgroundColor: theme.palette.neutral.extraLight,
  minHeight: visibility === 'hidden' ? '0' : '44px',
  minWidth: '44px',
  p: {
    color: theme.palette.neutral.main,
  },
}))

const InputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  padding: theme.spacing(0, 1),
}))

const HintContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const InputHint = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  padding: theme.spacing(1.25),
}))

const CodeBuilderInputWrapper = ({
  children,
  hint,
  inputDescription,
  marginTop,
  error,
}) => {
  const renderInputDescription = () => {
    if (inputDescription) {
      return inputDescription
    }

    return <Typography variant="body1">Question</Typography>
  }

  const renderHint = () => {
    return (
      hint && (
        <HintContainer>
          <InputDescription visibility="hidden">
            {renderInputDescription()}
          </InputDescription>
          <InputHint variant="caption">{hint}</InputHint>
        </HintContainer>
      )
    )
  }

  return (
    <Container marginTop={marginTop}>
      <InputContainer>
        <InputDescription>{renderInputDescription()}</InputDescription>
        <InputWrapper>{children}</InputWrapper>
      </InputContainer>
      {renderHint()}
      {error && <FormFieldError errorMsg={error} allowClose={false} />}
    </Container>
  )
}

export default CodeBuilderInputWrapper
