import { useEffect, useState, useContext } from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { Card } from '../dashboard/v2/DashboardCardComponents'
import CheckBoxStyled from '../common/CheckBoxStyled'
import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { ReactComponent as ReferralMoneyIcon } from '../../icons/referralMoneyIcon.svg'

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
  marginBottom: '15px',
}))

const OptOutContainer = styled('div')({
  paddingTop: '20px',
})

const ProgramLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.tabBlue.main,
  cursor: 'pointer',
  fontWeight: theme.fontWeights.semiBold,
}))

const ProgramDetailsInner = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
}))

const ProgramDetails = ({}) => {
  const [optOut, setOptOut] = useState(false)
  const [hasPayoutInfo, setHasPayoutInfo] = useState(false)
  const { setNotification } = useContext(AuthenticationContext)

  useEffect(() => {
    const fetchPayoutSettings = async () => {
      try {
        const res = await Api.getReferralPayoutSettings()

        if (!res.errors) {
          setOptOut(res.opt_out)
          setHasPayoutInfo(res.has_payout_info)
        } else {
          throw res.errors
        }
      } catch (err) {
        setNotification(err)
      }
    }

    fetchPayoutSettings()
  }, [])

  const toggleOptOut = async () => {
    try {
      const res = await Api.editReferralPayoutSettings({
        referral_payout_setting: {
          opt_out: !optOut,
        },
      })

      if (!res.errors) {
        setOptOut(!optOut)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  return (
    <Card>
      <ProgramDetailsInner>
        {optOut && (
          <StyledTypography variant="h5" component="h1">
            You've opt'd out. Uncheck the box to opt back in.
          </StyledTypography>
        )}
        {!optOut && (
          <StyledTypography variant="h5" component="h1">
            <ReferralMoneyIcon />{' '}
            {hasPayoutInfo
              ? 'You have joined our referral program!'
              : "Let's make sure we can pay you!"}
          </StyledTypography>
        )}
        {!hasPayoutInfo && (
          <StyledTypography variant="body1">
            <b>You haven't officially joined the Talkadot referral program.</b>
            <br />
            <br />
            <ProgramLink href="https://www.talkadot.com/share" target="_blank">
              Please agree to the conditions and submit your payout information
              securely here.
            </ProgramLink>
          </StyledTypography>
        )}
        <StyledTypography variant="body1">
          We currently payout via ACH transfer within 60 days after your
          referral upgrades to a paid Pro Plan or higher, and does not cancel
          within the 30 day money back guarantee.
        </StyledTypography>
        <StyledTypography variant="body1">
          Please Note: we are currently only able to make referral payments in
          the US, but we are tracking all of your referrals and you will receive
          credit for everyone as soon as your country is supported!
        </StyledTypography>
        {hasPayoutInfo && (
          <StyledTypography variant="body1">
            Has your payment information changed?{' '}
            <ProgramLink href="https://www.talkadot.com/share" target="_blank">
              Please submit an update here.
            </ProgramLink>
          </StyledTypography>
        )}
        <OptOutContainer>
          <StyledTypography>
            <b>
              {hasPayoutInfo
                ? 'No longer interested in this program?'
                : 'Not interested in this program?'}
            </b>
          </StyledTypography>
          <CheckBoxStyled
            label={
              !optOut
                ? "I would like to opt out of the referral program.  I'm happy to recommend Talkadot but I don't want to receive any thank you payments or reminder notices."
                : "You've opt'd out. Uncheck this box to opt back in"
            }
            checked={optOut}
            onChange={toggleOptOut}
          />
        </OptOutContainer>
      </ProgramDetailsInner>
    </Card>
  )
}

export default ProgramDetails
