export const sanitizeUserName = (str) =>
  // Replace non-alphanumeric characters with an empty string
  str ? str.replace(/[^A-Za-z0-9]/g, '').toLowerCase() : ''

export const capitalize = (str) => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const randomCodeString = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export const parseAutoFillTags = ({ question, speakerName }) => {
  const { content, replacementTarget, replacementValue } = question

  let parsedContent = content || ''

  if (replacementTarget) {
    parsedContent = parsedContent.replace(replacementTarget, replacementValue)
  }

  if (parsedContent.includes('{{speaker_name}}')) {
    parsedContent = parsedContent.replace('{{speaker_name}}', speakerName)
  }

  return parsedContent
}

export const parseEmojiKeywords = (question) => {
  const { content, replacementTarget, replacementValue } = question

  let parsedContent = content || ''

  if (replacementTarget) {
    parsedContent = parsedContent.replace(replacementTarget, replacementValue)
  }

  return { ...question, content: parsedContent }
}

export const parseSpeakerName = (question, speakerName) => {
  const { content } = question

  let parsedContent = content || ''

  if (parsedContent.includes('{{speaker_name}}')) {
    parsedContent = parsedContent.replace('{{speaker_name}}', speakerName)
  }

  return { ...question, content: parsedContent }
}

export const stripHtmlTags = (question) => {
  const { content } = question

  const parsedContent = content.replace(/<[^>]*>?/gm, '')

  return { ...question, content: parsedContent }
}

export const stripEmojiTags = (question) => {
  const { content, replacementTarget } = question

  let parsedContent = content.replace(replacementTarget, '')

  parsedContent = parsedContent.replace(/\?$/, '')

  return { ...question, content: parsedContent }
}
