import { styled } from '@mui/system'
import { FormControl, Select, MenuItem, InputBase } from '@mui/material'

const StyledFormControl = styled(FormControl)({
  flex: 1,
  height: '20px',
})

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    fontFamily: 'Figtree, sans-serif',
    fontWeight: '400',
    fontSize: '0.875rem',
    border: 'none',
    position: 'relative',
    backgroundColor: 'transparent',
    color: theme.palette.neutral.main,
    textOverflow: 'ellipsis',
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.neutral.light,
      opacity: '50%',
    },
  },
}))

const CodeBuilderInputSelect = ({
  value,
  options,
  placeholder,
  handleOnChange,
  disabled,
}) => {
  return (
    <StyledFormControl variant="standard">
      <Select
        value={value}
        onChange={(e) => handleOnChange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        input={<StyledInput />}>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.keyword}>
            {option.keyword}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}

export default CodeBuilderInputSelect
