import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { formatDate } from '../common/helpers'

import { Helmet } from 'react-helmet'

import TotalAttendees from './TotalAttendees'
import TalkValue from './TalkValue'
import TalkReattend from './TalkReattend'
import TalkEngagement from './TalkEngagement'
import Banner from './Banner'
import ReportCardFeedbackList from './ReportCardFeedbackList'
import SpeakerHeader from '../speakerProfile/SpeakerHeader'
import ShareEventReportCard from './ShareEventReportCard'
import EventPlannerEngagement from './EventPlannerEngagement'

import './EventReportCard.scss'

const initialReportDataState = {
  speaker: {
    id: null,
    name: null,
    avatar: null,
    isEventPlanner: null,
  },
  id: null,
  talkTitle: null,
  organization: null,
  date: null,
  totalResponses: null,
  ratingsBreakdown: {},
  feedback: [],
  improvement: [],
  takeaways: [],
  reattendBreakdown: {},
  isEventOwner: false,
  engagementBreakdown: [],
  speakers: [],
  eventType: null,
  isUserEvent: null,
  showBranding: null,
  access: {
    isEventOwner: null,
    isEventSpeaker: null,
    canShare: null,
  },
}

// const DataSummaryContainer = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   margin: theme.spacing(2.5, 0),
// }))

const EventReportCardParent = () => {
  let params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [customQuestionsLoading, setCustomQuestionsLoading] = useState(false)
  const [reportData, setReportData] = useState(initialReportDataState)
  const [customQuestions, setCustomQuestions] = useState([])
  const { setNotification, authData } = useContext(AuthenticationContext)

  const { user } = authData

  useEffect(() => {
    const loadReportCardData = async () => {
      if (!params.sharelink) {
        return setNotification('Sorry, your request is invalid.')
      }

      const reqParams = {
        event_report: {
          share_key: params.sharelink,
        },
      }

      try {
        setIsLoading(true)
        const res = await Api.getEventReportCard(reqParams)
        if (!res.errors) {
          setReportData(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        setNotification(err)
      }
    }

    loadReportCardData()
  }, [])

  useEffect(() => {
    const loadCustomAnswers = async () => {
      if (!params.sharelink) {
        return
      }

      try {
        setCustomQuestionsLoading(true)

        const reqParams = {
          event_report: {
            share_key: params.sharelink,
          },
        }

        const res = await Api.getCustomAnswers(reqParams)

        if (!res.errors) {
          setCustomQuestions(res.customQuestions)
          setCustomQuestionsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setCustomQuestionsLoading(false)
      }
    }

    if (reportData.id) {
      loadCustomAnswers()
    }
  }, [reportData.id, user?.id])

  const isEventOwner = reportData?.access?.isEventOwner
  const canShareReport = reportData?.access?.canShare
  const canUseFeedbackActions = reportData?.access?.canUseFeedbackActions
  const isUserEvent = reportData?.isUserEvent
  const filterFeedback = (feedback) => {
    if (isEventOwner) return feedback

    return feedback.filter((feedback) => feedback.public_share === true)
  }

  const renderEmptyReport = () => (
    <div className="event-report-card__empty-report-text">
      <Typography variant="h4">
        <b>Your attendee feedback will appear here!</b>
      </Typography>
      <br />
      <Typography variant="h5">
        Make sure to have your Talkadot Slide (or QR code) added to the last
        page of your talk to collect feedback.
      </Typography>
    </div>
  )

  // If there is only one customQuestion we call it "Custom Question" to keep it consistent with how this section is currently rendered
  const generateTitle = (customQuestion, customQuestions) => {
    const baseTitle =
      customQuestions.length > 1 ? customQuestion.content : 'Custom Question'
    const visibilitySuffix = customQuestion.isQuestionPublic
      ? ''
      : ' (only visible to you)'
    return `${baseTitle}${visibilitySuffix}`
  }

  return (
    <div className="event-report-card">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {canShareReport && (
        <ShareEventReportCard
          shareKey={params.sharelink}
          sortedFeedback={reportData.takeaways}
          reportData={reportData}
          isLoading={isLoading}
        />
      )}
      {isUserEvent && <SpeakerHeader id={null} shareKey={params.sharelink} />}
      <Banner
        speaker={reportData.speaker}
        talkTitle={reportData.talkTitle}
        organization={reportData.organization}
        date={formatDate(reportData.date, reportData.utcOffset)}
        speakers={reportData.speakers}
      />
      {!isLoading && !reportData.totalResponses ? (
        renderEmptyReport()
      ) : (
        <div className="event-report-card__data-summary">
          {/* <DataSummaryContainer> */}
          <TotalAttendees
            totalResponses={reportData.totalResponses}
            isLoading={isLoading}
          />
          <TalkValue
            ratingsBreakdown={reportData.ratingsBreakdown}
            isLoading={isLoading}
            eventType={reportData.eventType}
          />
          <TalkReattend
            reattendBreakdown={reportData.reattendBreakdown}
            isLoading={isLoading}
            speaker={reportData.speaker}
            eventType={reportData.eventType}
          />
          {
            /* TODO: Update this logic once we add event planner tooling to main app */
            reportData?.speaker?.isEventPlanner &&
            reportData.eventType?.toUpperCase() !== 'KEYNOTE' ? (
              <EventPlannerEngagement
                engagementBreakdown={reportData.engagementBreakdown}
                isLoading={isLoading}
              />
            ) : (
              <TalkEngagement
                engagementBreakdown={reportData.engagementBreakdown}
                isLoading={isLoading}
              />
            )
          }
          {/* </DataSummaryContainer> */}
        </div>
      )}
      {!!reportData.totalResponses && (
        <div className="event-report-card__feedback-summary">
          <ReportCardFeedbackList
            sortedFeedback={filterFeedback(reportData.takeaways)}
            title="Attendee Testimonials"
            isLoading={isLoading}
            reportData={reportData}
            shareKey={params.sharelink}
            canShareReport={canShareReport}
            canUseFeedbackActions={canUseFeedbackActions}
            setReportData={setReportData}
            canFavourite={true}
          />
          {!reportData?.speaker?.isEventPlanner && (
            <>
              <br />
              <ReportCardFeedbackList
                sortedFeedback={filterFeedback(reportData.feedback)}
                title="Attendee Feedback"
                isLoading={isLoading}
                reportData={reportData}
                shareKey={params.sharelink}
                canShareReport={canShareReport}
                canUseFeedbackActions={canUseFeedbackActions}
                setReportData={setReportData}
                canFavourite={true}
              />
            </>
          )}
          <br />
          <br />
          {reportData?.improvement.length > 0 && (
            <ReportCardFeedbackList
              sortedFeedback={reportData.improvement}
              title="Suggestions For Improvement (only visible to you)"
              feedbackType="improvement"
              reportData={reportData}
              shareKey={params.sharelink}
              canShareReport={canShareReport}
              canUseFeedbackActions={canUseFeedbackActions}
            />
          )}
        </div>
      )}

      {customQuestions.map((customQuestion, index) => (
        <div className="event-report-card__custom-responses">
          <ReportCardFeedbackList
            key={index}
            sortedFeedback={customQuestion.answers?.filter(
              (answer) => answer.text
            )}
            title={generateTitle(customQuestion, customQuestions)}
            isLoading={customQuestionsLoading}
            subTitle={customQuestions.length > 1 ? '' : customQuestion.content}
            reportData={reportData}
            shareKey={params.sharelink}
            canShareReport={canShareReport}
            canUseFeedbackActions={canUseFeedbackActions}
          />
        </div>
      ))}
    </div>
  )
}

export default EventReportCardParent
