import { useEffect, useState } from 'react'
import { styled } from '@mui/system'

import { Typography } from '@mui/material'

import CodeBuilderInputWrapper from './CodeBuilderInputWrapper'
import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputSelect from './CodeBuilderInputSelect'

import {
  usePermissionHelper,
  CUSTOMIZATIONS,
} from '../../../utils/permission_helper'

import Api from '../../../services/api'

import {
  parseEmojiKeywords,
  parseSpeakerName,
  stripHtmlTags,
  stripEmojiTags,
} from '../../../utils/string'

const SelectWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(0.5),
  fontFamily: 'Figtree, sans-serif',
  fontWeight: '400',
  fontSize: '0.875rem',
  p: {
    color: theme.palette.neutral.main,
  },
}))

const CodeBuilderSettingsQuestion = ({
  question,
  updateQuestion,
  setCurrentQuestion,
  questionLoading,
  speakerName,
  emojiKeywords,
}) => {
  const [error, setError] = useState(null)
  const [defaultKeywordOptions, setDefaultKeywordOptions] = useState([])
  const [keywordsLoading, setKeywordsLoading] = useState(false)

  const { config, questionType } = question

  const { findPermission } = usePermissionHelper()
  const customizationsPermission = findPermission(CUSTOMIZATIONS)

  useEffect(() => {
    if (questionType === 'EMOJI_SCALE' && !defaultKeywordOptions.length) {
      fetchKeywordOptions()
    }
  }, [questionType])

  const canEditContent =
    config?.canEditContent && customizationsPermission?.enabled

  const fetchKeywordOptions = async () => {
    try {
      setKeywordsLoading(true)

      const res = await Api.fetchDefaultEmojiKeywords()

      if (!res.errors) {
        setDefaultKeywordOptions(res)
        setKeywordsLoading(false)
      }
    } catch (err) {
      console.log(err)
      setKeywordsLoading(false)
    }
  }

  const renderInput = () => {
    switch (questionType) {
      case 'EMOJI_SCALE':
        const parsedEmojiContent = stripHtmlTags(
          stripEmojiTags(parseSpeakerName(question, speakerName))
        )

        // Filter out the currently selected emoji keywords except for one for the current question
        const availableKeywordOptions = defaultKeywordOptions.filter(
          (keyword) =>
            !emojiKeywords.includes(keyword.keyword) ||
            keyword.keyword === question.replacementValue
        )

        return (
          <SelectWrapper>
            <Typography>{parsedEmojiContent.content}</Typography>
            <CodeBuilderInputSelect
              placeholder="Select a keyword"
              value={question.replacementValue}
              options={availableKeywordOptions}
              disabled={questionLoading || keywordsLoading}
              handleOnChange={(newValue) =>
                updateQuestion(question.id, { replacement_value: newValue })
              }
            />
          </SelectWrapper>
        )
      default:
        const parsedContent = stripEmojiTags(
          stripHtmlTags(
            parseSpeakerName(parseEmojiKeywords(question), speakerName)
          )
        )

        return (
          <CodeBuilderInputText
            label="Question"
            placeholder="Enter a question"
            value={parsedContent.content}
            canEdit={canEditContent}
            error={error}
            setError={setError}
            setCurrentQuestion={setCurrentQuestion}
            handleOnChange={(newValue) =>
              setCurrentQuestion({ ...question, content: newValue })
            }
            handleOnBlur={(newValue) =>
              updateQuestion(question.id, { content: newValue })
            }
            disabled={questionLoading}
          />
        )
    }
  }

  return (
    <CodeBuilderInputWrapper error={error}>
      {renderInput()}
    </CodeBuilderInputWrapper>
    // TODO: Add other settings components here
  )
}

export default CodeBuilderSettingsQuestion
