import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import CodeBuilderSettingsTitle from './CodeBuilderSettingsTitle'
import CodeBuilderInputWrapper from './CodeBuilderInputWrapper'
import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputIcon from './CodeBuilderInputIcon'

import { SocialLinkIcon } from '@talkadot/survey-component-library'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { OnboardingContext } from '../../onboarding/onboardingContext'

const StyledPrefix = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

const socialMediaTypes = ['twitter', 'instagram', 'linkedin', 'youtube']

const CodeBuilderSettingsSocialLinks = () => {
  const [socialLinkError, setSocialLinkError] = useState(false)
  const [socialLinkLoading, setSocialLinkLoading] = useState(false)

  const { submitSocialMediaData } = useContext(OnboardingContext)
  const { authData, setSocialAttribute } = useContext(AuthenticationContext)
  const { user } = authData

  const handleSocialLinkBlur = async () => {
    setSocialLinkLoading(true)
    await submitSocialMediaData()
    setSocialLinkLoading(false)
  }

  const getPrefix = (socialType) => {
    switch (socialType) {
      case 'twitter':
      case 'instagram':
        return '@'
      case 'linkedin':
        if (user?.socialLinks[socialType]?.includes('linkedin.com')) {
          return ''
        } else if (user?.socialLinks[socialType]) {
          return 'linkedin.com/in/'
        }
        return ''
      case 'youtube':
        if (user?.socialLinks[socialType]?.includes('youtube.com/')) {
          return ''
        } else if (user?.socialLinks[socialType]) {
          return 'youtube.com/channel/'
        }
        return ''
      default:
        return ''
    }
  }

  const renderPrefix = (socialType) => {
    const prefix = getPrefix(socialType)

    if (!prefix) return null

    return (
      <StyledPrefix variant="body1" component="div">
        {prefix}
      </StyledPrefix>
    )
  }

  const renderSocialLinkInputs = () => {
    return socialMediaTypes.map((socialType) => {
      return (
        <CodeBuilderInputWrapper
          key={socialType}
          hint=""
          marginTop="1.5rem"
          error={socialLinkError}
          inputDescription={
            <CodeBuilderInputIcon
              icon={<SocialLinkIcon socialType={socialType} />}
              fillType="fill"
            />
          }>
          {renderPrefix(socialType)}
          <CodeBuilderInputText
            value={user?.socialLinks[socialType] || ''}
            handleOnChange={(value) => setSocialAttribute(socialType, value)}
            handleOnBlur={handleSocialLinkBlur}
            placeholder={`<my-${socialType}-handle`}
            canEdit={true}
            error={socialLinkError}
            setError={setSocialLinkError}
            disabled={socialLinkLoading}
            disableLeftPadding={getPrefix(socialType).length > 0}
          />
        </CodeBuilderInputWrapper>
      )
    })
  }

  return (
    <>
      <CodeBuilderSettingsTitle title="How do you want your audience to keep in touch?" />
      {renderSocialLinkInputs()}
    </>
  )
}

export default CodeBuilderSettingsSocialLinks
