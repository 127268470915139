import { styled } from '@mui/system'
import { Typography, IconButton } from '@mui/material'

// import { ReactComponent as DragIcon } from '../../../icons/burgerIcon_16x16.svg'
import { ReactComponent as EyeIcon } from '../../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../../icons/eyeOffIcon_16x16.svg'

const QuestionItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: theme.spacing(5.5),
  padding: theme.spacing(0, 1.75),
  backgroundColor: selected
    ? theme.palette.neutral.main
    : theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
  border: `1px solid ${theme.palette.border.light}`,
  cursor: 'pointer',
}))

// const DragHandleContainer = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   paddingRight: theme.spacing(1.75),
//   minWidth: '28px',
// }))

// const StyledDragIcon = styled(DragIcon)(({ theme }) => ({
//   cursor: 'grab',
//   path: {
//     fill: theme.palette.text.disabled,
//   },
// }))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  color: selected ? theme.palette.base.white : theme.palette.text.disabled,
  fontWeight: theme.fontWeights.semiBold,
  paddingLeft: theme.spacing(1.75),
  flex: 1,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  svg: {
    path: {
      stroke: theme.palette.text.disabled,
    },
  },
}))

const CodeBuilderPreviewQuestionItem = ({
  question,
  selected,
  sortable = false,
  canToggleEnable = false,
  setCurrentQuestion,
  questionNumber,
  updateQuestion,
}) => {
  const handleClick = () => {
    if (!selected) {
      setCurrentQuestion(question)
    }
  }

  const updateQuestionEnabled = (questionId) => {
    updateQuestion(questionId, { enabled: !question.enabled })
  }

  return (
    <QuestionItemContainer selected={selected} onClick={handleClick}>
      {/* TODO: enable drag handle once we implement this functionality */}
      {/* <DragHandleContainer selected={selected}>
        {sortable && <StyledDragIcon />}
      </DragHandleContainer> */}
      <StyledTypography variant="body1" selected={selected}>
        {`${questionNumber}. ${question.config?.previewName}`}
      </StyledTypography>
      {canToggleEnable && (
        <StyledIconButton onClick={() => updateQuestionEnabled(question.id)}>
          {question.enabled ? <EyeIcon /> : <EyeOffIcon />}
        </StyledIconButton>
      )}
    </QuestionItemContainer>
  )
}

export default CodeBuilderPreviewQuestionItem
