import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'
import Dropzone from 'react-dropzone'

import { ReactComponent as CloudUploadIcon } from '../../../icons/cloudUploadIcon.svg'
import { ReactComponent as FolderIcon } from '../../../icons/folderIcon_16x16.svg'
import { ReactComponent as LinkIcon } from '../../../icons/linkIcon_16x16.svg'

import CodeBuilderSettingsTitle from './CodeBuilderSettingsTitle'
import CodeBuilderInputWrapper from './CodeBuilderInputWrapper'
import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputIcon from './CodeBuilderInputIcon'
import CodeBuilderUploadFile from './CodeBuilderUploadFile'

import { uploadToS3, presignUrl, cancelUpload } from '../../../utils/fileUpload'
// import { validateUrlFormat } from '../../../utils/validators'

const DropzoneWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3.75),
}))

const DropzoneContainer = styled('div')(({ theme, isDragActive }) => ({
  alignItems: 'center',
  border: isDragActive
    ? `1px solid ${theme.palette.primary.main}`
    : `1px dashed ${theme.palette.border.main}`,
  width: '360px',
  padding: theme.spacing(2.5, 7.5),
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius.sm,
  backgroundColor: isDragActive ? theme.palette.action.hover : 'transparent',
  transition: 'background-color 0.3s ease',
}))

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  color: theme.palette.base.white,
  height: '32px',
  borderRadius: theme.shape.borderRadius.xs,
  svg: {
    path: {
      stroke: theme.palette.base.white,
    },
  },
}))

const DropzoneText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.hint,
  div: {
    fontWeight: theme.fontWeights.semiBold,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: theme.fontWeights.semiBold,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const CodeBuilderSettingsBonus = ({
  surveyOffer,
  surveyOfferLoading,
  updateCode,
}) => {
  const [uploading, setUploading] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState(null)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [offerLink, setOfferLink] = useState(surveyOffer?.link || '')
  const [offerLinkError, setOfferLinkError] = useState(null)

  useEffect(() => {
    if (surveyOffer?.link !== offerLink) {
      setOfferLink(surveyOffer?.link)
    }
  }, [surveyOffer?.link])

  const handleFileUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0]
    setAcceptedFile(file)

    if (!file) {
      return
    }

    try {
      const { name, type } = file

      const presignedRes = await presignUrl({ fileName: name, fileType: type })
      const { presignedUrl, fileKey } = presignedRes

      if (!presignedUrl) {
        throw 'Sorry, something went wrong uploading your file'
      }

      await uploadToS3({
        presignedUrl,
        file,
        setUploading,
        onUploadProgress: setUploadProgress,
        setCancelTokenSource,
      })
      await updateCode({ file_key: fileKey, file_name: name })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleUploadCancel = () => {
    if (cancelTokenSource) {
      cancelUpload(cancelTokenSource)
    }
    setUploading(false)
    setUploadProgress(0)
  }

  const handleUploadDelete = () => {
    setAcceptedFile(null)
    // TODO: delete file from S3
    updateCode({ file_key: null, file_name: null })
  }

  const parseFileRejectionError = (errorCode) => {
    switch (errorCode) {
      case 'file-invalid-type':
        return 'Invalid file type. Please upload a PDF, DOC, or PNG file.'
      case 'file-too-large':
        return 'File is too large. Please upload a file less than 20MB.'
      default:
        return 'Sorry, something went wrong uploading your file'
    }
  }

  const renderFileRejectionErrors = (fileRejection) => {
    const { errors } = fileRejection
    return errors.map((error) => {
      return (
        <ErrorText variant="body1" component="div">
          {parseFileRejectionError(error.code)}
        </ErrorText>
      )
    })
  }

  const handleOfferLinkBlur = () => {
    // if (!offerLink) {
    //   return updateCode({ link: null })
    // } else if (!validateUrlFormat(offerLink).valid) {
    //   return setOfferLinkError('Invalid URL')
    // }

    // NOTE: we don't currently validate the URL format.
    // In the survey client offer link click handler
    // we add a leading // to the URL if it doesn't include http(s)://

    updateCode({ link: offerLink })
  }

  return (
    <>
      <CodeBuilderSettingsTitle title="Do you want to give something to your audience?" />
      <DropzoneWrapper>
        <Dropzone
          onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
          accept={{ 'image/*': [], 'application/pdf': [] }} // TODO: check if we need to support more file types
          maxFiles={1}
          maxSize={20000000} // 20MB
        >
          {({ getRootProps, getInputProps, isDragActive, fileRejections }) => (
            <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
              <input {...getInputProps()} />
              <div>
                {fileRejections.length > 0 &&
                  renderFileRejectionErrors(fileRejections[0])}
              </div>
              <CloudUploadIcon />
              <DropzoneText variant="body2" component="div">
                <div>
                  {isDragActive
                    ? 'Drop the files here...'
                    : 'Drag and drop a file to upload'}
                </div>
              </DropzoneText>
              <DropzoneText variant="body1">
                {/* We actually currently support way more file types */}
                PDF, DOC, PNG up to 20MB
              </DropzoneText>
              {!uploading && (
                <UploadButton
                  variant="contained"
                  color="primary"
                  startIcon={<FolderIcon />}>
                  Browse Files
                </UploadButton>
              )}
            </DropzoneContainer>
          )}
        </Dropzone>
      </DropzoneWrapper>
      {(surveyOffer?.fileName || uploading) && (
        <CodeBuilderUploadFile
          fileName={surveyOffer?.fileName || acceptedFile?.name}
          fileSize={acceptedFile?.size}
          uploading={uploading}
          uploadProgress={uploadProgress}
          handleUploadCancel={handleUploadCancel}
          handleUploadDelete={handleUploadDelete}
        />
      )}
      {!surveyOffer?.fileName && !uploading && (
        <CodeBuilderInputWrapper
          hint="Use a link to give away more than one bonus"
          marginTop="1.5rem"
          error={offerLinkError}
          inputDescription={
            <CodeBuilderInputIcon icon={<LinkIcon />} fillType="stroke" />
          }>
          <CodeBuilderInputText
            value={offerLink || ''}
            handleOnChange={setOfferLink}
            handleOnBlur={handleOfferLinkBlur}
            placeholder="Paste a link to your bonus"
            canEdit={true}
            error={offerLinkError}
            setError={setOfferLinkError}
            disabled={surveyOfferLoading}
          />
        </CodeBuilderInputWrapper>
      )}
    </>
  )
}

export default CodeBuilderSettingsBonus
