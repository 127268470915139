import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import CodeBuilderQuestionHeader from './CodeBuilderQuestionHeader'
import CodeBuilderSettingsCode from './CodeBuilderSettingsCode'
import CodeBuilderSettingsBonus from './CodeBuilderSettingsBonus'
import CodeBuilderSettingsBookingLink from './CodeBuilderSettingsBookingLink'
import CodeBuilderSettingsSocialLinks from './CodeBuilderSettingsSocialLinks'
import CodeBuilderSettingsQuestion from './CodeBuilderSettingsQuestion'

const QuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const QuestionContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '1.25rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const QuestionNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '42px',
  height: '42px',
  padding: '0.625rem',
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius.xs,
  h5: {
    color: theme.palette.base.white,
  },
}))

const QuestionSettingsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

const CodeBuilderQuestion = ({
  surveyOffer,
  question,
  questionNumber,
  updateQuestion,
  setCurrentQuestion,
  code,
  setCode,
  description,
  setDescription,
  updateCode,
  surveyOfferLoading,
  questionLoading,
  speakerName,
  emojiKeywords,
}) => {
  const renderQuestionSettings = () => {
    switch (question.questionType) {
      case 'code':
        return (
          <CodeBuilderSettingsCode
            surveyOffer={surveyOffer}
            code={code}
            setCode={setCode}
            description={description}
            setDescription={setDescription}
            updateCode={updateCode}
            surveyOfferLoading={surveyOfferLoading}
          />
        )
      case 'bonus':
        return (
          <CodeBuilderSettingsBonus
            surveyOffer={surveyOffer}
            surveyOfferLoading={surveyOfferLoading}
            updateCode={updateCode}
          />
        )
      case 'bookingLink':
        return <CodeBuilderSettingsBookingLink />
      case 'socialLinks':
        return <CodeBuilderSettingsSocialLinks />
      default:
        return (
          <CodeBuilderSettingsQuestion
            question={question}
            updateQuestion={updateQuestion}
            setCurrentQuestion={setCurrentQuestion}
            questionLoading={questionLoading}
            speakerName={speakerName}
            emojiKeywords={emojiKeywords}
          />
        )
    }
  }

  return (
    <QuestionContainer>
      <CodeBuilderQuestionHeader
        question={question}
        updateQuestion={updateQuestion}
        questionLoading={questionLoading}
      />
      <QuestionContainerInner>
        <QuestionNumber>
          <Typography variant="h5">{questionNumber}</Typography>
        </QuestionNumber>
        <QuestionSettingsContainer>
          {renderQuestionSettings()}
        </QuestionSettingsContainer>
      </QuestionContainerInner>
    </QuestionContainer>
  )
}

export default CodeBuilderQuestion
