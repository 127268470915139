import { useEffect, useState } from 'react'
import { styled } from '@mui/system'

const TextInput = styled('input', {
  shouldForwardProp: (prop) =>
    prop !== 'fontWeight' && prop !== 'error' && prop !== 'disableLeftPadding',
})(({ theme, fontWeight, error, disableLeftPadding }) => ({
  width: '100%',
  transition: '300ms all ease-in-out',
  fontFamily: 'Figtree, sans-serif',
  fontWeight: fontWeight || '400',
  fontSize: '0.875rem',
  border: 'none',
  position: 'relative',
  backgroundColor: 'transparent',
  color: error ? theme.palette.error.main : theme.palette.neutral.main,
  textOverflow: 'ellipsis',
  paddingLeft: disableLeftPadding ? '0' : '',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: theme.palette.neutral.light,
    opacity: '50%',
  },
}))

const CodeBuilderInputText = ({
  value,
  inputValidation,
  placeholder,
  fontWeight,
  canEdit,
  handleOnChange,
  handleOnBlur,
  disabled,
  error,
  setError,
  disableLeftPadding,
}) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (value && value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  const handleChange = (e) => {
    if (!canEdit) {
      return
    }

    if (error) {
      setError(null)
    }

    const validationError = inputValidation && inputValidation(e.target.value)

    if (validationError?.message) {
      console.log(validationError.message)

      setError(validationError.message)
      if (!validationError?.allowContinue) {
        return
      }
    }

    setInputValue(e.target.value)
    handleOnChange(e.target.value)
  }

  const handleBlur = (e) => {
    if (canEdit && !error) {
      handleOnBlur(e.target.value)
    }
  }

  return (
    <TextInput
      type="text"
      onChange={(e) => handleChange(e)}
      onBlur={(e) => handleBlur(e)}
      value={inputValue}
      placeholder={placeholder}
      fontWeight={fontWeight}
      disabled={disabled || !canEdit}
      disableLeftPadding={disableLeftPadding}
    />
  )
}

export default CodeBuilderInputText
