import { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { styled } from '@mui/system'

import PageHeader from '../../common/PageHeader'
import CodeBuilderQuestion from './CodeBuilderQuestion'
import CodeBuilderNavigation from './CodeBuilderNavigation'
import CodeBuilderPreviewPaneParent from './CodeBuilderPreviewPaneParent'
import LoadingSpinner from '../../common/LoadingSpinner'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import Api from '../../../services/api'

import { randomCodeString } from '../../../utils/string'

const CodeBuilderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
}))

const QuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'min-content',
  padding: theme.spacing(5, 2.5),
  backgroundColor: theme.palette.base.white,
}))

const MultiQuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4.5),
}))

const LoadingSpinnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
  width: '100%',
})

const initialQuestionState = {
  questionType: '',
}

export const codeQuestionState = {
  questionType: 'code',
  config: { previewName: 'General Setup' },
}

export const bonusQuestionState = {
  questionType: 'bonus',
  config: {
    previewName: 'Bonus and Socials',
    questionTypeDescription: 'Bonus Uploader',
  },
}

const bookingLinkQuestionState = {
  questionType: 'bookingLink',
  config: {
    questionTypeDescription: 'Calendar Booking',
  },
}

const socialLinksQuestionState = {
  questionType: 'socialLinks',
  config: {
    questionTypeDescription: 'Social Links',
  },
}

const CodeBuilderParent = () => {
  const [surveyOffer, setSurveyOffer] = useState({})
  const [code, setCode] = useState('')
  const [bonusUrl, setBonusUrl] = useState('')
  const [description, setDescription] = useState('')
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(initialQuestionState)
  const [surveyOfferLoading, setSurveyOfferLoading] = useState(false)
  const [questionLoading, setQuestionLoading] = useState(false)
  const { surveyOfferId } = useParams()
  const history = useHistory()

  const { authData, setNotification } = useContext(AuthenticationContext)
  const { user } = authData

  useEffect(() => {
    const getCode = async (surveyOfferId) => {
      try {
        setSurveyOfferLoading(true)
        const res = await Api.getCode(surveyOfferId)

        if (!res.errors) {
          const filteredQuestions = res.questions.filter(
            (question) => !question.config.hiddenFromBuilder
          )

          const offerBonusUrl = res.downloadUrl || res.link

          setSurveyOffer(res)
          setQuestions(filteredQuestions)
          setCurrentQuestion(filteredQuestions[0])
          setCode(res.code)
          setBonusUrl(offerBonusUrl)
          setDescription(res.name)
          setSurveyOfferLoading(false)
        } else {
          // handle error
          throw res.errors
        }
      } catch (err) {
        setSurveyOfferLoading(false)
        return setNotification(err)
      }
    }

    // if surveyOfferId is not null, fetch the survey offer
    // this means the user is editing an existing survey offer
    if (surveyOfferId) {
      // fetch survey offer
      getCode(surveyOfferId)
      return
    }

    // if surveyOfferId is null, set currentQuestion to 'code'
    // this means the user is creating a new survey offer
    setCurrentQuestion(codeQuestionState)
    setCode(randomCodeString(4))
  }, [surveyOfferId])

  const createNewCode = async () => {
    try {
      setSurveyOfferLoading(true)
      const res = await Api.createCode({ survey_offer: { code } })

      if (!res.errors) {
        history.push(`/codes/edit/${res.id}`)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setSurveyOfferLoading(false)
      setNotification(err)
      setCurrentQuestion(codeQuestionState)
    }
  }

  const updateCode = async (data) => {
    if (!surveyOffer.id) return

    try {
      setSurveyOfferLoading(true)

      const params = {
        survey_offer: {
          ...data,
        },
      }

      const res = await Api.updateCode(surveyOffer.id, params)

      if (!res.errors) {
        setSurveyOffer(res)

        if (code !== res.code) {
          setCode(res.code)
        }

        const offerBonusUrl = res.downloadUrl || res.link

        if (bonusUrl !== offerBonusUrl) {
          setBonusUrl(offerBonusUrl)
        }

        setSurveyOfferLoading(false)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setSurveyOfferLoading(false)
      return setNotification(err)
    }
  }

  const updateQuestion = async (questionId, data) => {
    try {
      setQuestionLoading(true)

      const params = {
        question: {
          ...data,
        },
      }

      const res = await Api.updateQuestion(questionId, params)

      if (!res.errors) {
        // multiple questions can be updated by this action
        // update question state for all questions that were updated
        const updatedQuestions = questions.map((question) => {
          const updatedQuestion = res.updatedQuestions.find(
            (q) => q.id === question.id
          )
          if (updatedQuestion) {
            return updatedQuestion
          }
          return question
        })

        const updatedQuestion = updatedQuestions.find(
          (q) => q.id === questionId
        )

        setCurrentQuestion(updatedQuestion)
        setQuestions(updatedQuestions)
        setQuestionLoading(false)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setQuestionLoading(false)
      return setNotification(err)
    }
  }

  const handleBack = () => {
    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestion.id
    )
    if (currentIndex > 0) {
      setCurrentQuestion(questions[currentIndex - 1])
    }

    if (currentIndex === 0) {
      setCurrentQuestion({
        questionType: 'code',
        config: { previewName: 'General Setup' },
      })
    }

    if (currentQuestion.questionType === 'bonus') {
      setCurrentQuestion(questions[questions.length - 1])
    }

    if (currentQuestion.questionType === 'download') {
      setCurrentQuestion(bonusQuestionState)
    }
  }

  const handleNext = () => {
    // if surveyOffer.id does not exist, create a new survey offer
    if (!surveyOffer.id && currentQuestion.questionType === 'code') {
      createNewCode()
    }

    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestion.id
    )

    if (currentIndex >= 0 && currentIndex < questions.length - 1) {
      setCurrentQuestion(questions[currentIndex + 1])
    }

    if (currentIndex === questions.length - 1) {
      setCurrentQuestion(bonusQuestionState)
    }

    if (currentQuestion.questionType === 'code') {
      setCurrentQuestion(questions[0])
    }

    if (currentQuestion.questionType === 'bonus') {
      setCurrentQuestion({
        questionType: 'download',
        config: { previewName: 'Download Slide' },
      })
    }
  }

  const handleSkip = () => {
    // TODO: create a new survey offer
    setCurrentQuestion(bonusQuestionState)
  }

  const questionNumber = () => {
    if (currentQuestion.questionType === 'code') {
      return 1
    }

    if (currentQuestion.questionType === 'bonus') {
      return questions.length + 3
    }

    return (
      questions.findIndex((question) => question.id === currentQuestion.id) + 2
    )
  }

  // Parse the currently selected emoji keywords
  const emojiKeywords = () => {
    const emojiQuestions = questions.filter(
      (question) => question.questionType === 'EMOJI_SCALE'
    )

    return emojiQuestions.map((question) => question.replacementValue)
  }

  const renderQuestions = () => {
    if (currentQuestion.questionType === 'bonus') {
      return (
        <MultiQuestionContainer>
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={currentQuestion}
            questionNumber={questionNumber()}
            code={code}
            setCode={setCode}
            updateCode={updateCode}
          />
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={bookingLinkQuestionState}
            questionNumber={questionNumber() + 'a'}
            code={code}
            setCode={setCode}
          />
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={socialLinksQuestionState}
            questionNumber={questionNumber() + 'b'}
            code={code}
            setCode={setCode}
          />
        </MultiQuestionContainer>
      )
    }

    return (
      <CodeBuilderQuestion
        surveyOffer={surveyOffer}
        question={currentQuestion}
        questionNumber={questionNumber()}
        updateQuestion={updateQuestion}
        setCurrentQuestion={setCurrentQuestion}
        code={code}
        setCode={setCode}
        description={description}
        setDescription={setDescription}
        updateCode={updateCode}
        surveyOfferLoading={surveyOfferLoading}
        questionLoading={questionLoading}
        speakerName={user?.first_name}
        emojiKeywords={emojiKeywords()}
      />
    )
  }

  const renderContent = () => {
    if (surveyOfferLoading && !currentQuestion?.questionType) {
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner size={50} />
        </LoadingSpinnerContainer>
      )
    }

    if (currentQuestion?.questionType) {
      return (
        <>
          <QuestionContainer>
            {renderQuestions()}
            <CodeBuilderNavigation
              handleBack={handleBack}
              handleNext={handleNext}
              handleSkip={handleSkip}
              showBackButton={currentQuestion.questionType !== 'code'}
              showBuilderSkipButton={
                !surveyOffer?.id && currentQuestion.questionType === 'code'
              }
            />
          </QuestionContainer>
          <CodeBuilderPreviewPaneParent
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            currentQuestionNumber={questionNumber()}
            updateQuestion={updateQuestion}
            questions={questions}
            user={user}
            code={code}
            bonusUrl={bonusUrl}
          />
        </>
      )
    }
  }

  return (
    <>
      <PageHeader header="Your Talkadot Code Setup" />
      <CodeBuilderContainer>{renderContent()}</CodeBuilderContainer>
    </>
  )
}

export default CodeBuilderParent
