import { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import {
  Paper,
  Typography,
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  CssBaseline,
} from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { Helmet } from 'react-helmet'

import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import WebhookIcon from '@mui/icons-material/Webhook'
import PersonIcon from '@mui/icons-material/Person'

import AuthDocumentation from './AuthDocumentation'
import WebhooksDocumentation from './WebhooksDocumentation'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3),
  whiteSpace: 'pre-line',
  textAlign: 'left',
}))

export const StyledHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export const StyledSubheading = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

export const StyledCodeBlock = styled('pre')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius.sm,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  whiteSpace: 'pre-wrap',
}))

const ApiDocumentationParent = () => {
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const [activeTab, setActiveTab] = useState('Authentication')

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const renderMarkdown = () => {
    switch (activeTab) {
      case 'Authentication':
        return <AuthDocumentation />
      case 'Webhooks':
        return <WebhooksDocumentation />
      default:
        return <AuthDocumentation />
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Talkadot API Documentation
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {['Authentication', 'Webhooks'].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                onClick={() => setActiveTab(text)}>
                <ListItemButton selected={activeTab === text}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <PersonIcon /> : <WebhookIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {renderMarkdown()}
        </Main>
      </Box>
    </>
  )
}

export default ApiDocumentationParent
