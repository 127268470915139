import { styled } from '@mui/system'
import { Button, useMediaQuery } from '@mui/material'

import { ReactComponent as ArrowLeft } from '../../../icons/arrowLeft.svg'

const NavContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'singleButton',
})(({ theme, singleButton }) => ({
  display: 'flex',
  justifyContent: singleButton ? 'flex-end' : 'space-between',
  padding: theme.spacing(3, 0),
}))

const NavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'direction',
})(({ theme, disabled, direction }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  color: theme.palette.base.white,
  svg: {
    minWidth: '16px',
    transform: direction === 'right' ? 'rotate(180deg)' : 'none',
    path: {
      stroke: disabled
        ? theme.palette.action.disabled
        : theme.palette.base.white,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .button-text': {
      display: 'none',
    },
  },
}))

const SkipButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.fontWeights.regular,
}))

const CodeBuilderNavigation = ({
  handleBack,
  handleNext,
  handleSkip,
  showBackButton,
  showBuilderSkipButton,
}) => {
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const renderSkipButtonText = () => {
    if (isSmallScreen) {
      return 'Use defaults'
    }

    if (isLargeScreen) {
      return 'I want to use all Talkadot defaults. Go straight to add a bonus'
    }

    return 'Use defaults. Add a bonus'
  }

  return (
    <NavContainer singleButton={!showBackButton && !showBuilderSkipButton}>
      {showBackButton && (
        <NavButton variant="contained" onClick={handleBack}>
          <ArrowLeft />
          <div className="button-text">Back</div>
        </NavButton>
      )}
      {showBuilderSkipButton && (
        <SkipButton variant="text" color="secondary" onClick={handleSkip}>
          {renderSkipButtonText()}
        </SkipButton>
      )}
      <NavButton variant="contained" direction="right" onClick={handleNext}>
        <span className="button-text">Next</span>
        <ArrowLeft />
      </NavButton>
    </NavContainer>
  )
}

export default CodeBuilderNavigation
