import { useState, useContext } from 'react'

import CodeBuilderSettingsTitle from './CodeBuilderSettingsTitle'
import CodeBuilderInputWrapper from './CodeBuilderInputWrapper'
import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputIcon from './CodeBuilderInputIcon'

import { ReactComponent as LinkIcon } from '../../../icons/linkIcon_16x16.svg'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { usePermissionHelper } from '../../../utils/permission_helper'

import UpgradeModalLauncher from '../../common/UpgradeModalLauncher'

const CodeBuilderSettingsBookingLink = () => {
  const [bookingLinkError, setBookingLinkError] = useState(false)
  const [bookingLinkLoading, setBookingLinkLoading] = useState(false)

  const { authData, setProfileAttribute, submitBookingLink } = useContext(
    AuthenticationContext
  )
  const { user } = authData

  const isOnPaidPlan =
    usePermissionHelper().onPaidPlan() && !user?.membership?.isTrialing

  const handleBookingLinkBlur = async () => {
    setBookingLinkLoading(true)
    await submitBookingLink()
    setBookingLinkLoading(false)
  }

  const handleOnChange = (value) => {
    setProfileAttribute('booking_link', value)
  }

  return (
    <>
      <CodeBuilderSettingsTitle title="Allow qualified leads to schedule a meeting with you?" />
      {!isOnPaidPlan && (
        <div>
          <UpgradeModalLauncher
            content="Upgrade To Add"
            variant="outlined"
            upgradeHelperText="Upgrade To Add Your Calendar Link To Your Talkadot Flow!"
            permissionType={null}
          />
        </div>
      )}
      <CodeBuilderInputWrapper
        hint="Only qualified leads who give you their full name and full contact information can schedule with you."
        marginTop="1.5rem"
        error={bookingLinkError}
        inputDescription={
          <CodeBuilderInputIcon icon={<LinkIcon />} fillType="stroke" />
        }>
        <CodeBuilderInputText
          value={user?.booking_link || ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleBookingLinkBlur}
          placeholder="Paste your scheduling link - e.g. Calendly"
          canEdit={true}
          error={bookingLinkError}
          setError={setBookingLinkError}
          disabled={bookingLinkLoading || !isOnPaidPlan}
        />
      </CodeBuilderInputWrapper>
    </>
  )
}

export default CodeBuilderSettingsBookingLink
