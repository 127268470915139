import { useState } from 'react'
import { styled } from '@mui/system'
import { Tabs, Tab } from '@mui/material'

import CodeBuilderPreviewQuestionItem from './CodeBuilderPreviewQuestionItem'
import CodeBuilderPreviewPane from './CodeBuilderPreviewPane'

import { codeQuestionState, bonusQuestionState } from './CodeBuilderParent'

const PreviewContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '336px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const StyledTabs = styled(Tabs)({
  ' .MuiTabs-flexContainer': {
    alignItems: 'flex-end',
  },
})

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 72,
  fontWeight: '400',
  color: theme.palette.text.disabled,
  marginRight: theme.spacing(0.5),
  height: '52px',
  borderRadius: `${theme.shape.borderRadius.xs} ${theme.shape.borderRadius.xs} 0 0`,
  backgroundColor: theme.palette.base.white,
  '&:last-child': {
    marginRight: 0,
  },
  '&.Mui-selected': {
    color: theme.palette.text.inputText,
    fontWeight: '600',
    border: `1px solid ${theme.palette.border.light}`,
    borderBottom: 'none',
  },
  '&:not(.Mui-selected)': {
    border: `6px solid ${theme.palette.border.light}`,
    height: '45px',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}))

const TabContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '600px',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.base.white,
  borderRadius: `0 0 ${theme.shape.borderRadius.xs} ${theme.shape.borderRadius.xs}`,
  border: `1px solid ${theme.palette.border.light}`,
  borderTop: 'none',
}))

const CodeBuilderPreviewPaneParent = ({
  currentQuestion,
  currentQuestionNumber,
  setCurrentQuestion,
  updateQuestion,
  questions,
  user,
  code,
  bonusUrl,
}) => {
  const [activeTab, setActiveTab] = useState(1)

  const renderTabContent = () => {
    if (activeTab === 0) {
      const questionList = questions.map((question, index) => {
        const {
          config: { canToggleEnable },
        } = question

        return (
          <CodeBuilderPreviewQuestionItem
            key={index}
            question={question}
            selected={currentQuestion.id === question.id}
            sortable={false}
            canToggleEnable={canToggleEnable}
            questionNumber={index + 2}
            setCurrentQuestion={setCurrentQuestion}
            updateQuestion={updateQuestion}
          />
        )
      })

      const questionListWithCodeAndBonus = [
        <CodeBuilderPreviewQuestionItem
          key="code"
          question={codeQuestionState}
          selected={currentQuestion.questionType === 'code'}
          sortable={false}
          canDisable={false}
          questionNumber={1}
          setCurrentQuestion={setCurrentQuestion}
        />,
        ...questionList,
        <CodeBuilderPreviewQuestionItem
          key="bonus"
          question={bonusQuestionState}
          selected={currentQuestion.questionType === 'bonus'}
          sortable={false}
          canDisable={false}
          questionNumber={questions.length + 3}
          setCurrentQuestion={setCurrentQuestion}
        />,
      ]

      return questionListWithCodeAndBonus
    } else {
      const {
        config: { canToggleEnable },
      } = currentQuestion

      return (
        <>
          <CodeBuilderPreviewQuestionItem
            question={currentQuestion}
            selected={true}
            sortable={false}
            canToggleEnable={canToggleEnable}
            questionNumber={currentQuestionNumber}
            setCurrentQuestion={() => {}}
            updateQuestion={updateQuestion}
          />
          <CodeBuilderPreviewPane
            currentQuestion={currentQuestion}
            user={user}
            code={code}
            bonusUrl={bonusUrl}
          />
        </>
      )
    }
  }

  return (
    <PreviewContainer>
      <StyledTabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        TabIndicatorProps={{
          style: { display: 'none' },
        }}>
        <StyledTab label="All Questions" disabled={questions.length === 0} />
        <StyledTab label="Preview Feedback" />
      </StyledTabs>
      <TabContentContainer>{renderTabContent()}</TabContentContainer>
    </PreviewContainer>
  )
}

export default CodeBuilderPreviewPaneParent
